import { PropsWithChildren } from "react";

type PageContainerProps = {
  isLoginPage?: boolean;
};

export const PageContainer = ({
  children,
  isLoginPage
}: PropsWithChildren<PageContainerProps>) => {
  const loginPageContainerClassNames = !isLoginPage ? 'rounded-xl pr-10 pl-10 bg-[#36364F]' : '';

  return (
    <div className={`h-full ${loginPageContainerClassNames}`}>
      {children}
    </div>
  );
};