import { createContext, useContext, PropsWithChildren, useState } from "react";

export type InitStateType = {
    data: any[];
    setData: (data: any[]) => void;
    currentRow: any;
    setCurrentRow: (row: any) => void;
};

const initState: InitStateType = {
    data: [],
    setData: (_data: any[]) => {},
    currentRow: null,
    setCurrentRow: (_row: any) => {},
};

const ResultsContext = createContext(initState);

export const useResultsContext = () => {
    const context = useContext(ResultsContext);

    return context;
};

export const ResultsProvider = ({
    children
}: PropsWithChildren) => {
    const [data, setData] = useState<any[]>([]);
    const [currentRow, setCurrentRow] = useState<any>();

    return (
        <ResultsContext.Provider value={{ data, setData, currentRow, setCurrentRow }}>
            {children}
        </ResultsContext.Provider>
    );
};
