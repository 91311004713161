export type Option = {
    name: string;
    target_torque: string;
    process_id: string;
    target_angle: string;
};
// event type is any because changing event type to React.FormEvent<HTMLDesignItSelectElement> will cause an issue
// with the event.target.value property

// Function to filter names based on process id selection
export const handleProcessIdChange = (
    event: any,
    setSelectedProcessId: (processId: string) => void,
    setFilteredNames: (names: string[]) => void,
    options: Option[]
) => {
    const processId = event.target.value;
    setSelectedProcessId(processId);

    // Filter the names associated with the selected process ID
    const names = options
        .filter(option => option.process_id === processId)
        .map(option => option.name);
    setFilteredNames(names);
};


// Function to filter torques and angle based on name selection
export const handleNameChange = (
    event: any, 
    setConfigName: (name: string) => void,
    setFilteredTorques: (torques: string[]) => void,
    options: Option[]
) => {
    const name = event.target.value;
    setConfigName(name);

    const torques = options
        .filter(option => option.name === name)
        .map(option => option.target_torque);
    setFilteredTorques(torques);
};

// Function to filter angles based on name and torque selection
export const handleTorqueChange = (
    event: any,
    configName: string | null,
    setSelectedTorque: (torque: string) => void,
    setFilteredAngles: (angles: string[]) => void,
    options: Option[]
) => {
    setSelectedTorque(event.target.value);
    const angles = options
        .filter(option => option.name === configName && option.target_angle !== "")
        .map(option => option.target_angle);
    setFilteredAngles(angles);
};


// Function to set angles based on torque and name selection
export const handleAngleChange = (
    event: any,
    setSelectedAngle: (angle: string) => void
) => {
    setSelectedAngle(event.target.value);
};