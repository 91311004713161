import * as amplify from "@aws-amplify/auth";
import moment from "moment";
import { apiHelper } from "./apiHelper";
import { useQuery } from "@tanstack/react-query";

const URL =
  "https://ghokvtd8oj.execute-api.eu-west-1.amazonaws.com/default/get_progress";

const getPollProgress = async (jobId: string | null): Promise<number> => {
  if (!jobId) {
    return 0;
  }

  const userId = (await amplify.getCurrentUser())["userId"];
  const response = await apiHelper.post(URL, {
    user_id: userId,
    job_id: jobId,
  });

  const body = JSON.parse(response.body as string);
  let totalStages = 0;
  let stagesDone = 0;

  for (const key in body) {
    if (typeof body[key] === "number") {
      stagesDone += body[key];
      totalStages += 1;
    }
  }

  const actualProgress = stagesDone / Math.max(totalStages, 1);

  return actualProgress;
};

export const useGetPollProgress = (jobId: string | null) =>
  useQuery({
    queryKey: ["use-get-poll-progress", jobId],
    queryFn: () => getPollProgress(jobId),
    refetchInterval: 1000,
  });
