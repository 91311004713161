import { useNavigate, useSearchParams } from "react-router-dom";
import { ResultsTable } from "./ResultsTable";
import { useGetBoltingDataWithAnomalies } from "../../apis/useGetBoltingData";
import { useStepperContext } from "../../providers/StepperProvider";
import { useEffect } from "react";
import { DesignItButton } from "@design-it/react-library";
import { downloadPdf } from "../../utils/reportHelpers";

export const ResultsPage = () => {
  const { setStep, setShowStepper } = useStepperContext();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const fileName = searchParams.get("fileName");

  useEffect(() => {
    setStep(4);
    setShowStepper(true);
  }, []);

  const { data, isLoading } = useGetBoltingDataWithAnomalies();

  // @TODO change with a lambda from here
  // const {
  // 	data: allBoltingData,
  // 	isLoading: allBoltingDataLoading,
  // } = useGetBoltingData();

  // useEffect(() => {
  // 	if (allBoltingData?.find((d: BoltingData) => d.feedback === 'NONE')) {
  // 		navigate(`/labelling?fileName=${fileName}`);
  // 	}
  // }, [allBoltingData]);
  // to here

  if (isLoading && !data) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <div className="table-title">Results</div>

      <div className="table-subtitle">
        Based upon your given feedback on the previously trained data, we
        generated some results for you. You can download them.
      </div>

      <ResultsTable
        className="h-[60vh]"
        data={data!}
        isLoading={isLoading}
        onArrowClick={row => navigate(`/results/${row.id}`)}
      />

      <div className="footer-buttons">
        <DesignItButton
          variant="outlined"
          onClick={() =>
            navigate(`/data-check?fileName=${fileName}&lastStep=results`)
          }
        >
          <span>Go back</span>
        </DesignItButton>

        <DesignItButton variant="filled-tonal" onClick={() => downloadPdf()}>
          <span>Download</span>
        </DesignItButton>
      </div>
    </div>
  );
};
