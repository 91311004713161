import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { Provider } from "react-redux";
import store from "./store";
import { ThemeProvider } from "./theme/themeContext";
import "./index.scss";
import { ResultsProvider } from "./providers/resultsProvider";
import { ReactQueryProvider } from "./providers/ReactQueryProvider";
import { StepperProvider } from "./providers/StepperProvider";
import { CSVProvider } from './providers/CSVProvider';
import { BrowserRouter as Router } from 'react-router-dom';

const root = ReactDOM.createRoot(
	document.getElementById("root") as HTMLElement
);

root.render(
	<React.StrictMode>
		<Provider store={store}>
			<ReactQueryProvider>
				<ThemeProvider>
					<StepperProvider>
						<ResultsProvider>
							<CSVProvider>
						    <Router>
									<App />
								</Router>
							</CSVProvider>
						</ResultsProvider>
					</StepperProvider>
				</ThemeProvider>
			</ReactQueryProvider>
		</Provider>
	</React.StrictMode>
);	
