import { useNavigate, useSearchParams, Navigate } from "react-router-dom";
import { useGetBoltingData } from "../../apis/useGetBoltingData";
import { LabellingTable } from "./LabellingTable";
import { DesignItButton } from "@design-it/react-library";
import { BoltingData } from "../../apis/types";
import { useUpdateBoltingDataFeedback } from "../../apis/useSaveBoltingDataFeedback";
import { UpdateBoltingDataFeedback } from "../../apis/useSaveBoltingDataFeedback";
import { useEffect, useMemo } from "react";
import { useStepperContext } from "../../providers/StepperProvider";
import { verifyModelConfig } from "../upload/uploadHelpers";
import "./LabellingPage.scss";

export const LabellingPage = () => {
  const navigate = useNavigate();
  const { data, isLoading } = useGetBoltingData();
  const { mutate: updateFeedback } = useUpdateBoltingDataFeedback({
    onSuccess: () => navigate("/analysis"),
  });
  const [searchParams] = useSearchParams();
  const fileName = searchParams.get("fileName");
  const configName = searchParams.get("configName");

  const { setStep, setShowStepper } = useStepperContext();

  const feedbackData: UpdateBoltingDataFeedback[] = useMemo(() => {
    return (
      data?.map((item: BoltingData) => ({
        PartitionKey: item["productId#processId"],
        SortKey: item.createdDate,
        Feedback: item.feedback,
      })) ?? []
    );
  }, [data]);

  const saveAndContinueDisabled =
    data?.findIndex((d: BoltingData) => d.feedback === "NONE") > -1;

  const onSaveAndContinueClick = () => {
    if (!saveAndContinueDisabled) {
      updateFeedback(feedbackData);
      verifyModelConfig(configName ?? "config1");
    }
  };

  useEffect(() => {
    setStep(3);
    setShowStepper(true);
  }, []);

  const onBackClick = () => {
    let redirectURL = `/data-check?fileName=${fileName}`;

    if (saveAndContinueDisabled) {
      redirectURL += `&lastStep=labelling`;
    }

    navigate(redirectURL);
  };

  if (!fileName) {
    return <Navigate to={"/analysis"} />;
  }

  return (
    <div className="w-full">
      <div className="text-center p-2p text-lg w-full">
        Please see the AI generated results
      </div>

      <div className="p-1p flex justify-center items-center">
        <LabellingTable
          data={data}
          isLoading={isLoading}
          className="h-[70vh]"
        />
      </div>

      <div className="flex justify-end">
        <div className="mr-4 mb-4 labelling-table-button">
          <DesignItButton
            onClick={() => navigate(`/data-check?fileName=${fileName}`)}
          >
            Go Back
          </DesignItButton>
        </div>

        <div className="mr-4 mb-4 labelling-table-button">
          <DesignItButton
            onClick={onSaveAndContinueClick}
            disabled={saveAndContinueDisabled}
          >
            Save & Continue
          </DesignItButton>
        </div>
      </div>
    </div>
  );
};
