import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { DesignItTag } from "@design-it/react-library";
import Grid3x3Icon from "@mui/icons-material/Grid3x3";
import AccessAlarmsIcon from "@mui/icons-material/AccessAlarms";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HdrAutoIcon from "@mui/icons-material/HdrAuto";
import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";
import { CustomCellRendererProps } from '@ag-grid-community/react';
import { format } from 'date-fns';
import { DbBoltingData, BoltingData } from "../../apis/types";

import "./resultsTable.scss";

type CustomHeaderProps = {
	icon: React.ReactElement;
	title: string;
};

type ResultsTableProps = {
	onArrowClick?: (row: DbBoltingData) => void;
	data: BoltingData[];
	isLoading: boolean;
	className?: string;
	hideArrow?: boolean;
};

export const ResultsTable = ({
	onArrowClick,
	data,
	isLoading,
	className,
	hideArrow = false
}: ResultsTableProps) => {
	const iconStyle = {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		fontSize: "22px",
		cursor: "pointer"
	};

	const CustomHeader = (props: CustomHeaderProps) => {
		return (
			<div className="custom-header">
				<span className="header-icon">{props.icon} </span>
				<span className="header-title">{props.title}</span>
			</div>
		);
	};

	const filteredData = data?.filter((d: any) => d.anomalyDetected === "yes") ?? [];

	const getColDefs = () => {
		let colDefs: any[] = [
			{
				field: "id",
				headerComponent: () => (
					<CustomHeader icon={<Grid3x3Icon className="header-icon" fontSize="small" />}
						title="ID" />
				),
				flex: 4,
				cellClass: "custom-cell",
				cellRenderer: (props: CustomCellRendererProps) => {
					return `${props.data.productId}#${props.data.processId}`;
				},
			},
			{
				field: "prgNr",
				headerComponent: () => (
					<CustomHeader icon={<Grid3x3Icon className="header-icon" fontSize="small" />}
						title="Cluster ID" />
				),
				flex: 2,
				cellClass: "custom-cell",
			},
			{
				field: "status",
				headerComponent: () => (
					<CustomHeader icon={<CheckCircleOutlineIcon className="header-icon" fontSize="small" />}
						title="Bolting Tool Result" />
				),
				flex: 3,
				cellClass: "custom-cell",
			},
			{
				field: "createdDate",
				headerComponent: () => (
					<CustomHeader icon={
						<AccessAlarmsIcon className="header-icon" fontSize="small" />
					}
						title="Timestamp Result" />
				),
				cellRenderer: (props: CustomCellRendererProps) => {
					return format(new Date(props.value), 'dd.MM.yyyy | HH:mm');
				},
				flex: 3,
				cellClass: "custom-cell",
			},
			{
				field: "anomalyDetected",
				headerComponent: () => (
					<CustomHeader icon={<HdrAutoIcon className="header-icon" fontSize="small" />}
						title="AI Result" />
				),
				flex: 3,
				cellRenderer: (props: CustomCellRendererProps) => {
					if (props.value === "yes") {
						return (
							<DesignItTag
								className='ics-green-light'
								variant='error'
								icon-name='do_not_disturb_on'
								icon-position='first'
							>
								anomaly
							</DesignItTag>
						);
					}
	
					if (props.value === "no") {
						return (
							<DesignItTag
								className='ics-green-light'
								variant='success'
								icon-name='check_circle'
								icon-position='first'
							>
								no anomaly
							</DesignItTag>
						);
					}
				},
				cellClass: "custom-cell",
			},
		];

		if (!hideArrow) {
			colDefs.push({
				flex: 1,
				cellRenderer: (props: CustomCellRendererProps) =>
					<ArrowForwardRoundedIcon style={iconStyle}
						onClick={() => onArrowClick?.(props.data)} />,
				cellClass: "custom-cell",
			});
		}

		return colDefs;
	};

	return (
		<div className="result-layout">
			<div className={`table-layout ${className}`}>
				<AgGridReact
					rowData={filteredData}
					loading={isLoading}
					columnDefs={getColDefs()}
					rowClass="table-row" />
			</div>
		</div>
	);
};
