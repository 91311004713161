import React, { useEffect, useState } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { defineCustomElements } from "@design-it/web-core/loader";
import Login from "./pages/login/index";
import Error from "./pages/login/error";
import { UploadPage } from "./pages/upload/UploadPage";
import CommonAppBar from "./components/appBar";
import { SideMenu } from "./pages/dashboard/sideMenu";
import { AiComputingPage } from "./pages/ai-computing/AiComputingPage";
import { ResultsPage } from "./pages/results/ResultsPage";
import { BoltingDataDetailPage } from "./pages/bolting-data-detail/BoltingDataDetailPage";
import { LabellingPage } from "./pages/labelling/LabellingPage";
import { PageContainer } from "./components/PageContainer/PageContainer";
import { DataCheckPage } from "./pages/DataCheck/DataCheckPage";
import { AnalysisPage } from "./pages/AnalysisPage/AnalysisPage";

import "@design-it/web-core/dist/assets/themes/themes.scss";
import "@fontsource/orbitron";
import { useStepperContext } from "./providers/StepperProvider";
import { Stepper } from "./pages/dashboard/Stepper";
import { ProtectedRoute } from "./components/routeAuth/routeAuth";
import { StartPage } from "./pages/StartPage/StartPage";

const App: React.FC = () => {
  defineCustomElements();

  const [showAppBar, setShowAppBar] = useState(true);
  const [showSideMenu, setShowSideMenu] = useState(true);
  const location = useLocation();

  useEffect(() => {
    setShowSideMenu(location.pathname !== "/");
    setShowAppBar(location.pathname !== "/");
  }, [location]);

  const { step, showStepper } = useStepperContext();

  return (
    <div className="flex flex-col h-lvh">
      {showAppBar && <CommonAppBar />}

      <div className="flex-1 flex flex-row ics-green-dark">
        {showSideMenu && (
          <div className="w-1/5 h-full">
            <SideMenu />
          </div>
        )}

        <div
          className={`${!showSideMenu ? "w-full" : "w-4/5 pr-8 pb-8"} h-full`}
        >
          <PageContainer isLoginPage={!showSideMenu}>
            
            <Stepper step={step} shouldShowStepper={showStepper} />

            <Routes>
              <Route path="/" element={<Login />} />
              <Route element={<ProtectedRoute />}>
                <Route path="/upload" element={<UploadPage />} />
                <Route path="/analysis" element={<AnalysisPage />} />
                <Route path="/data-check" element={<DataCheckPage />} />
                <Route path="/ai-computing" element={<AiComputingPage />} />
                <Route path="/results" element={<ResultsPage />} />
                <Route
                  path="/results/:id"
                  element={<BoltingDataDetailPage />}
                />
                <Route path="/labelling" element={<LabellingPage />} />
                <Route
                  path="/labelling/:id"
                  element={<BoltingDataDetailPage modelExists={false} />}
                />
                <Route path="/data-check" element={<DataCheckPage />} />
                <Route path="/start" element={<StartPage />} />
                <Route
                  path="*"
                  element={<Error setShowSideMenu={setShowSideMenu} />}
                />
              </Route>
            </Routes>
          </PageContainer>
        </div>
      </div>
    </div>
  );
};

export default App;
