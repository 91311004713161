import { BoltingData, DbBoltingData } from "./types";

export const mapDbBoltingDataToBoltingData = (item: DbBoltingData): BoltingData => ({
    angleInDeg: item.angle_in_deg,
    anomalyDetected: item.anomaly_detected,
    createdDate: item.created_date,
    feedback: item.feedback,
    id: item.id,
    prgNr: item.prg_nr,
    processId: item.process_id,
    productId: item.product_id,
    "productId#processId": item["product_id#process_id"],
    reconstructedTorque: item.reconstructed_torque,
    status: item.status,
    timeInSec: item.time_in_sec,
    torqueInNm: item.torque_in_nm,
    userId: item.user_id,
});
