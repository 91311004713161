import React, { createContext, useState, useContext } from "react";

const ThemeContext = createContext("light");

interface ThemeProps {
	children: any;
}

const themeProps: ThemeProps | null = null;

export const ThemeProvider = (themeProps: { children: any }) => {
	const [theme, setTheme] = useState("light"); // Default to light theme

	const toggleTheme = () => {
		setTheme(prevTheme => (prevTheme === "light" ? "dark" : "light"));
	};

	return (
		<ThemeContext.Provider value={theme}>
			{themeProps?.children}
		</ThemeContext.Provider>
	);
};

export const useTheme = () => useContext(ThemeContext);
