import * as React from "react";
import { DesignItStepper, DesignItStepperStep } from "@design-it/react-library";

interface StepperProps {
	shouldShowStepper: boolean;
	step: number;
}

export function Stepper(props: StepperProps) {
	const getState = (stepperStep: number) => {
		if (stepperStep === props.step) {
			return "current";
		} else if (stepperStep < props.step) {
			return "success";
		} else {
			return "open";
		}
	};

	return (
		<>
			{props.shouldShowStepper && (
				<DesignItStepper className='pt-6'>
					<DesignItStepperStep
						state={getState(1)}
						label='UploadXpress'
						{...(getState(1) === "success" && { icon: "check" })}
					></DesignItStepperStep>
					<DesignItStepperStep
						state={getState(2)}
						label='Data Check'
						{...(getState(2) === "success" && { icon: "check" })}
					></DesignItStepperStep>
					<DesignItStepperStep
						state={getState(3)}
						label='Labelling'
						{...(getState(3) === "success" && { icon: "check" })}
					></DesignItStepperStep>
					<DesignItStepperStep //step number for result page needs to be added
						state={getState(4)}
						label='Results'
						{...(getState(4) === "success" && { icon: "check" })}
					></DesignItStepperStep>
				</DesignItStepper>
			)}
		</>
	);
}
