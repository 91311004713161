import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { DesignItTag } from "@design-it/react-library";
import Grid3x3Icon from "@mui/icons-material/Grid3x3";
import AccessAlarmsIcon from "@mui/icons-material/AccessAlarms";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HdrAutoIcon from "@mui/icons-material/HdrAuto";
import { CustomCellRendererProps } from '@ag-grid-community/react';
import { format } from 'date-fns';
import { BoltingData } from "../../apis/types";

import "../results/resultsTable.scss";
import { useNavigate } from "react-router-dom";

export enum FeedbackValues {
	NONE = "NONE",
	OK = "OK",
	NOK = "NOK",
}

type CustomHeaderProps = {
  icon: React.ReactElement;
  title: string;
};

type LabellingTableProps = {
  data: BoltingData[];
  isLoading: boolean;
  className?: string;
};

export const LabellingTable = ({
  data,
  isLoading,
  className
}: LabellingTableProps) => {
  const navigate = useNavigate();

  const CustomHeader = (props: CustomHeaderProps) => {
    return (
      <div className="custom-header">
        <span className="header-icon">{props.icon} </span>
        <span className="header-title">{props.title}</span>
      </div>
    );
  };

  const colDefs: any[] = [
    {
      field: "id",
      headerComponent: () => (
        <CustomHeader icon={<Grid3x3Icon className="header-icon" fontSize="small" />}
          title="ID" />
      ),
      flex: 4,
      cellClass: "custom-cell",
    },
    {
      field: "productId",
      headerComponent: () => (
        <CustomHeader icon={<Grid3x3Icon className="header-icon" fontSize="small" />}
          title="Product ID" />
      ),
      flex: 2,
      cellClass: "custom-cell",
      sortable: true,
      filter: 'agTextColumnFilter'
    },
    {
      field: "processId",
      headerComponent: () => (
        <CustomHeader icon={<Grid3x3Icon className="header-icon" fontSize="small" />}
          title="Process ID" />
      ),
      flex: 3,
      cellClass: "custom-cell",
    },
    {
      field: "prgNr",
      headerComponent: () => (
        <CustomHeader icon={<Grid3x3Icon className="header-icon" fontSize="small" />}
          title="Cluster ID" />
      ),
      flex: 2,
      cellClass: "custom-cell",
    },
    {
      field: "status",
      headerComponent: () => (
        <CustomHeader icon={<CheckCircleOutlineIcon className="header-icon" fontSize="small" />}
          title="Bolting Tool Result" />
      ),
      flex: 3,
      cellClass: "custom-cell",
    },
    {
      field: "createdDate",
      headerComponent: () => (
        <CustomHeader icon={
          <AccessAlarmsIcon className="header-icon" fontSize="small" />
        }
          title="Timestamp Result" />
      ),
      cellRenderer: (props: CustomCellRendererProps) => {
        return format(new Date(props.value), 'dd.MM.yyyy | HH:mm');
      },
      flex: 3,
      cellClass: "custom-cell",
    },
    {
      field: 'feedback',
      cellRenderer: (props: CustomCellRendererProps) => (
        <DesignItTag
          onClick={() => navigate(`/labelling/${props.data.id}`)}
          key={props.value}
          className='ics-green-light'
          variant={
            props.value === FeedbackValues.NOK
              ? "error" : props.value === FeedbackValues.OK
              ? "success" : "surface"
          }
          icon-name={
            props.value === FeedbackValues.NOK
              ? "do_not_disturb_on" : props.value === FeedbackValues.OK
              ? "check_circle" : "info"
          }
          icon-position='first'
        >
          {
            props.value === FeedbackValues.NOK 
              ? "anomaly" : props.value === FeedbackValues.OK
              ? "no anomaly" : "wanna check this"
          }
        </DesignItTag>
      ),
      cellClass: "custom-cell",
    },
    {
      field: "anomalyDetected",
      headerComponent: () => (
        <CustomHeader icon={<HdrAutoIcon className="header-icon" fontSize="small" />}
          title="AI Result" />
      ),
      flex: 3,
      cellRenderer: (props: CustomCellRendererProps) => {
        if (props.value === "yes") {
          return (
            <DesignItTag
              className='ics-green-light'
              variant='error'
              icon-name='do_not_disturb_on'
              icon-position='first'
            >
              anomaly
            </DesignItTag>
          );
        }

        if (props.value === "no") {
          return (
            <DesignItTag
              className='ics-green-light'
              variant='success'
              icon-name='check_circle'
              icon-position='first'
            >
              no anomaly
            </DesignItTag>
          );
        }
      },
      cellClass: "custom-cell",
    },
  ];

  return (
    <div className={`table-layout flex-1 ${className}`}>
      <AgGridReact className="grid-layout"
        rowData={data}
        loading={isLoading}
        columnDefs={colDefs}
        rowClass="table-row" />
    </div>
  );
};
