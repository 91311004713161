import React, { useMemo } from "react";
import Plot from "react-plotly.js";
import { LayoutAxis } from "plotly.js";
import "./style.scss";
import { hexToRgba } from "./utils";
import { BoltingAttributes } from "../../pages/dashboard/fileUploadComponent";

interface ChartProps {
	lines: BoltingAttributes[];
}

const NormalizedGraph: React.FC<ChartProps> = ({ lines }) => {
	const magicConstant = 200;
	const baseAlpha = 0.6;

	const optimalAlpha = Math.max(
		0.02,
		(baseAlpha * magicConstant) / (lines.length + magicConstant)
	);

	const linePointsToPlot = 75;

	const data: Plotly.Data[] = lines.map(line => {
		const color = "#95CFE8";
		const alpha = optimalAlpha;
		const width = 1.2;

		const samplingRate = Math.ceil(
			line.torqueValues.length / linePointsToPlot
		);

		const xValues = line.angleValues.filter(
			(v: number, i: number) => i % samplingRate == 0
		);
		const yValues = line.torqueValues.filter(
			(v: number, i: number) => i % samplingRate == 0
		);
		xValues.push(line.angleValues[line.angleValues.length - 1]);
		yValues.push(line.torqueValues[line.torqueValues.length - 1]);

		return {
			x: xValues,
			y: yValues,
			type: "scattergl",
			mode: "lines",
			line: {
				color: hexToRgba(color, alpha),
				width: width,
			},
			hoverinfo: "x+y",
		};
	});

	const config: Partial<Plotly.Config> = {
		staticPlot: true,
		displayModeBar: false,
		responsive: true,
	};

	const layout: Partial<Plotly.Layout> = {
		margin: {
			b: 65,
			l: 60,
			r: 40,
			t: 30,
		},
		autosize: true,
		hovermode: "closest",
		showlegend: false,
		paper_bgcolor: "rgba(0, 0, 0, 0)",
		plot_bgcolor: "rgba(0, 0, 0, 0)",
		modebar: {
			remove: [
				"toImage",
				"sendDataToCloud",
				"zoom2d",
				"zoomIn2d",
				"zoomOut2d",
				"resetScale2d",
			],
		},
		xaxis: {
			showgrid: false,
			showline: true,
			zeroline: false,
			color: "#FDFBFB",
			title: {
				text: "Angle[°]",
			},
			anchor: "y",
		},
		yaxis: {
			showgrid: false,
			showline: true,
			color: "#FDFBFB",
			zeroline: false,
			title: {
				text: "Torque[Nm]",
			},
			ticklabelstandoff: 5,
			anchor: "x",
		} as Partial<LayoutAxis>,
	};

	return <Plot data={data} config={config} layout={layout} />;
};

export default NormalizedGraph;
