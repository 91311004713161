import { useParams } from "react-router-dom";
import { useGetBoltingDataById } from "../../apis/useGetBoltingData";
import { RowViewComponent } from "../dashboard/rowViewComponent";
import { useNavigate } from "react-router-dom";
import { useStepperContext } from "../../providers/StepperProvider";
import { useEffect } from "react";

type BoltingDataDetailPageProps = {
	modelExists?: boolean;
};

export const BoltingDataDetailPage = ({
	modelExists = true
}: BoltingDataDetailPageProps) => {
	const { id } = useParams();
	const { data, refetch, isLoading } = useGetBoltingDataById(id);
	const navigate = useNavigate();
	
	const { setShowStepper } = useStepperContext();

  useEffect(() => {
    setShowStepper(false);
  }, []);


	if (isLoading) {
		return <div>Loading...</div>;
	}

	return (
		<div>
			<RowViewComponent onClose={() => navigate(-1)}
				updateBoltingOperationDataWithFeedback={() => refetch()}
				modelExists={Boolean(modelExists)}
				selectedRow={data} />
		</div>
	);
};
