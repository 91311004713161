import {
  DesignItButton,
  DesignItSelect,
  DesignItSelectOption,
} from "@design-it/react-library";
import { Tooltip, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { CopyToClipboard } from "../../components/CopyToClipboard/CopyToClipboard";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { CLIPBOARD_TEXT, TOOLTIP_TEXT } from "./analysis.constants";
import { useStepperContext } from "../../providers/StepperProvider";
import { Navigate } from "react-router-dom";
import { UploadDropContainer } from "../../components/uploadDropContainer/uploadDropContainer";
import { TabProps, InputErrors } from "./types";
import { useCSVContext } from "../../providers/CSVProvider";
import { handleNameChange, handleProcessIdChange, handleTorqueChange, handleAngleChange, Option } from './AnalysisHelpers';
import { useModelConfigs } from './UseModelConfigs';

export const ExecuteAnalysis: React.FC<TabProps> = ({
  uploadContainerProps,
  ...props
}) => {
  const { setStep, setShowStepper } = useStepperContext();
  const { progress, setProgress, fileName, lines } = useCSVContext();

  useEffect(() => {
    setStep(1);
    setShowStepper(true);
    // cleanup --> move to proper API call for step2
    localStorage.removeItem("fileLines");
    localStorage.removeItem("lines");
  }, []);

  // Using the custom hook for model configurations
  const { loading, options, pidOptions } = useModelConfigs(props.modelConfigs);

  // State to hold model configurations
  const [selectedProcessId, setSelectedProcessId] = useState<string | null>(
    null
  );
  const [selectedTorque, setSelectedTorque] = useState<string | null>(null);
  const [selectedAngle, setSelectedAngle] = useState<string | null>(null);
  const [configName, setConfigName] = useState<string | null>(null);

  // State to manage the filtered dropdown options
  const [filteredNames, setFilteredNames] = useState<string[]>([]);
  const [filteredTorques, setFilteredTorques] = useState<string[]>([]);
  const [filteredAngles, setFilteredAngles] = useState<string[]>([]);

  const [inputErrors, setInputErrors] = useState<InputErrors>({
    processId: false,
    name: false,
    torque: false,
    file: false,
  });
  
  // Return loading indicator if data is still being fetched
  if (loading) {
    return <div>Loading...</div>;
  }

  if (progress === 100 && lines.length > 0) {
    setProgress(0);

    return (
      <Navigate
        to={`/data-check?fileName=${fileName}&configName=${configName}`}
      />
    );
  }

  const verifyUploadAndTrigger = () => {
    const newInputErrors = {
      processId: selectedProcessId === null,
      name: configName === null,
      torque: selectedTorque === null,
      file: !props.file,
    };

    setInputErrors(newInputErrors);
    const hasErrors = Object.values(newInputErrors).some(error => error);

    if (!hasErrors) {
      props.handleUpload();
    }
  };

  return (
    <div className="flex w-[100%] flex-col">
      <div className="flex w-[100%]">
        <div className="w-[50%] pt-[50px] pb-[50px] pl-[124px] pr-[124px]">
          <Typography>1. Choose an analysis job</Typography>

          <div className="flex gap-8 flex-col">
            <DesignItSelect
              variant="filled"
              required
              label="Process ID"
              disabled={!!selectedProcessId}
              onInput={(e) => handleProcessIdChange(e, setSelectedProcessId, setFilteredNames, options)}
              error={inputErrors.processId}
              errorText="This is a mandatory field"
            >
              {pidOptions.map(pid => (
                <DesignItSelectOption key={pid} value={pid} searchValue={pid}>
                  <div slot="headline">{pid}</div>
                </DesignItSelectOption>
              ))}
            </DesignItSelect>

            <DesignItSelect
              variant="filled"
              required
              label="Name your analysis job"
              disabled={!selectedProcessId || !!configName}
              onInput={(e) => handleNameChange(e, setConfigName, setFilteredTorques, options)}
              error={inputErrors.name}
              errorText="This is a mandatory field"
            >
              {filteredNames.map(name => (
                <DesignItSelectOption
                  key={name}
                  value={name}
                  searchValue={name}
                >
                  <div slot="headline">{name}</div>
                </DesignItSelectOption>
              ))}
            </DesignItSelect>

            <DesignItSelect
              variant="filled"
              required
              label="Target Torque"
              disabled={!selectedProcessId || !configName || !!selectedTorque}
              onInput={(e) => handleTorqueChange(e, configName, setSelectedTorque, setFilteredAngles, options)}
              error={inputErrors.torque}
              errorText="This is a mandatory field"
            >
              {filteredTorques.map(torque => (
                <DesignItSelectOption
                  key={torque}
                  value={torque}
                  searchValue={torque}
                >
                  <div slot="headline">{torque}</div>
                </DesignItSelectOption>
              ))}
            </DesignItSelect>

            <DesignItSelect
              variant="filled"
              label="Target Angle"
              disabled={
                !selectedProcessId ||
                !configName ||
                !selectedTorque ||
                !!selectedAngle ||
                filteredAngles.length === 0
              }
              onInput={(e) => handleAngleChange(e, setSelectedAngle)}
            >
              {filteredAngles.map(angle => (
                <DesignItSelectOption
                  key={angle}
                  value={angle}
                  searchValue={angle}
                >
                  <div slot="headline">{angle}</div>
                </DesignItSelectOption>
              ))}
            </DesignItSelect>
          </div>
          <p className="mt-[5px]">Optional</p>
        </div>

        <div className="w-[50%] pt-[50px] pb-[50px] pl-[124px] pr-[124px]">
          <p className="mb-5 text-lg">
            <Typography className="inline">
              <span className="inline">
                2. Upload an analysis file. Use this &nbsp;
              </span>
              <CopyToClipboard
                className="inline"
                text={CLIPBOARD_TEXT}
                label={"format "}
              />
              <Tooltip title={TOOLTIP_TEXT}>
                <HelpOutlineIcon fontSize={"small"} />
              </Tooltip>
            </Typography>
          </p>

          <UploadDropContainer
            {...uploadContainerProps}
            hasError={inputErrors.file}
          />

          <div className="flex my-10 mx-96 ">
            <DesignItButton
              variant="filled"
              disabled={
                !props.file ||
                !configName ||
                !selectedProcessId ||
                !selectedTorque
              }
              onClick={verifyUploadAndTrigger}
            >
              Let&apos;s go
            </DesignItButton>
          </div>
        </div>
      </div>
    </div>
  );
};
