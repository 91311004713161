import { DesignItIconButton } from "@design-it/react-library";
import DetailedGraphView from "../../components/graphView/detailedGraphViewComponent";
import BoltingOperationTable from "./boltingOperationTable";
import { FeedbackComponent } from "./feedbackComponent";
import Grid from "@mui/material/Grid";
import { useResultsContext } from "../../providers/resultsProvider";
import { OPERATION_TABLE_COLUMNS, OPERATION_TABLE_COLUMNS_MODEL_EXISTS } from "../../constants/tables/columnDefs";
import { BoltingData } from '../../apis/types';
import { AgGridReact } from "ag-grid-react";
import { LabellingTable } from "../labelling/LabellingTable";
import { ResultsTable } from "../results/ResultsTable";

interface RowViewComponentProps {
	onClose: () => void;
	updateBoltingOperationDataWithFeedback: any;
	modelExists: boolean;
	selectedRow?: BoltingData | null;
}

export function RowViewComponent({
	onClose,
	modelExists,
	selectedRow,
	...props
}: RowViewComponentProps) {
	const { currentRow, setCurrentRow } = useResultsContext();
	const rowToUse = selectedRow ?? currentRow;

	if (!rowToUse) {
		return <div>No data available...</div>;
	}

	return (
		<Grid container className='p-1p min-h-full max-h-full overflow-hidden'>
			<div className='flex items-center max-h-24 w-full justify-between'>
				<div className='flex items-center'>
					{!modelExists && (
						<FeedbackComponent
							row={rowToUse}
							updateBoltingOperationDataWithFeedback={
								props.updateBoltingOperationDataWithFeedback
							}
							updateSelectedRow={setCurrentRow}
						/>
					)}
				</div>

				<div className='max-h-fit'>
					<DesignItIconButton
						icon='close'
						variant='icon'
						onClick={onClose}
					/>
				</div>
			</div>

			<Grid item xs={12} className='h-full'>
				<div className='bg-white rounded-xl p-4'>
					{rowToUse && <DetailedGraphView row={rowToUse} />}

					<div className='pt-2p'>
						{modelExists && (
							<ResultsTable data={[rowToUse]}
								isLoading={false}
								hideArrow={true}
								className="h-[150px] mt-5" />
						)}

						{!modelExists && (
							<LabellingTable data={[rowToUse]} isLoading={false} className="h-[150px]" />
						)}
					</div>
				</div>
			</Grid>
		</Grid>
	);
}
