import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import { useNavigate } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import * as Auth from "@aws-amplify/auth";
import "@design-it/web-core/dist/assets/themes/themes.scss";
import "./index.scss";
import {
	DesignItButton,
	DesignItIcon,
	DesignItIconButton,
	DesignItTopBar,
} from "@design-it/react-library";
import { Link } from "react-router-dom";

export default function CommonAppBar() {
	const history = useNavigate();
	// Add a function to handle logout logic
	const handleLogout = async () => {
		try {
			await Auth.signOut();
			// Redirect to the sign-in page or perform other actions after sign out
			history("/");
		} catch (error) {
			console.error("Error signing out: ", error);
		}
	};

	return (
		<>
			<DesignItTopBar size='S'>
				<DesignItIconButton
					icon='menu'
					slot='appbar-start'
					variant='icon'
					className='inline pl-4 appbar-icon'
					color='white'
				/>
				<span slot='appbar-start'>
					<a href='/'>
						<span className='text-on-surface font-orbitron text-2xl'>
							bolt
						</span>
						<span className='text-primary font-orbitron text-2xl'>
							_it
						</span>
					</a>
				</span>
				{/* <DesignItIconButton
					icon='help'
					slot='appbar-end'
					variant='icon'
					className='inline text-white appbar-icon'
					color='white'
				/>
				<DesignItIconButton
					icon='dark_mode'
					slot='appbar-end'
					variant='icon'
					className='inline text-white appbar-icon'
					color='white'
				/> */}
				<DesignItIconButton
					icon='logout'
					slot='appbar-end'
					variant='icon'
					className='inline text-white appbar-icon pr-4'
					color='white'
					onClick={handleLogout}
				/>
			</DesignItTopBar>
		</>
	);
}
