import Grid from "@mui/material/Grid";
import NormalizedGraph from "../../components/normalizedGraph/graph";
import {
  DesignItButton,
  DesignItCard,
  DesignItProgress,
} from "@design-it/react-library";
import moment from "moment";
import * as Amplify from "@aws-amplify/auth";
import { apiHelper } from "../../apis/apiHelper";
import { updateUploadCount } from "../../utils/uploadLimitHelpers";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Navigate } from "react-router";
import { useStepperContext } from "../../providers/StepperProvider";
import { useEffect } from "react";
const CONFIGURATION_NAME = "config1";
import { useCSVContext } from "../../providers/CSVProvider";
import {
  downloadFromPresignedUrl,
  fetchPresignedUrl,
} from "../upload/uploadHelpers";
import { columnMapper } from "../dashboard/uploadHelper";

export const DataCheckPage = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const fileName = searchParams.get("fileName");
  const lastStep = searchParams.get("lastStep");
  const configName = searchParams.get("configName");

  const { setStep } = useStepperContext();

  const { lines, setLines } = useCSVContext();

  useEffect(() => {
    async function loadData() {
      if (!lines.length) {
        const preSignedUrl = await fetchPresignedUrl(fileName as string);
        const jsonData = await downloadFromPresignedUrl(preSignedUrl);
        const fileLines = jsonData.map(columnMapper);
        setLines(fileLines);
      }
    }
    loadData();
  }, []);

  if (!fileName) {
    return <Navigate to={"/analysis"} />;
  }

  const onContinueClick = async () => {
    if (lastStep === "results") {
      navigate(`/results?fileName=${fileName}`);
    } else if (lastStep === "labelling") {
      navigate(`/labelling?fileName=${fileName}`);
    } else {
      const jobId = moment().format("YYYY-MM-DD_HH:mm:ss.SSS");
      const userId = (await Amplify.getCurrentUser())["userId"];
      const uploadBucketName =
        process.env.REACT_APP_UPLOAD_BUCKET_NAME ?? "raw-upload-bucket";

      apiHelper.post(
        process.env.REACT_APP_REST_API_ROOT_URL + "/start-workflow",
        {
          "user-id": userId,
          "bucket-name": uploadBucketName,
          "file-key": `raw/${fileName}`,
          "job-id": jobId,
          "config-name": `${configName}`,
        }
      );

      updateUploadCount(1, userId);
      navigate(
        `/ai-computing?jobId=${jobId}&fileName=${fileName}&configName=${configName}`
      );
    }
  };

  useEffect(() => {
    setStep(2);
  }, []);

  return (
    <div className="justify-around flex flex-col w-full flex-1">
      <div className="flex-0 items-center text-xl text-left pt-5 pb-5 pl-5">
        Sample curves from your file look like this. Does this seem correct?
      </div>
      <Grid container className="flex flex-1">
        <Grid
          item
          xs={12}
          sx={{ height: "450px" }}
          className="flex items-center justify-center pr-5"
        >
          <DesignItCard
            state={undefined}
            className="bg-surface h-full w-full max-w-full max-h-full min-w-full min-h-full"
          >
            {lines.length === 0 ? (
              <div className="w-full h-full flex justify-center items-center flex-col">
                <DesignItProgress indeterminate />
                <p>Building Graph...</p>
              </div>
            ) : (
              <NormalizedGraph lines={lines} />
            )}
          </DesignItCard>
        </Grid>
      </Grid>
      <div className="flex flex-0 items-end justify-end p-5">
        <DesignItButton onClick={() => onContinueClick()}>
          Continue
        </DesignItButton>
      </div>
    </div>
  );
};
