import React from "react";
import {
	DesignItButton,
	DesignItCard,
	DesignItIconButton,
} from "@design-it/react-library";
import { apiHelper } from "../../apis/apiHelper";
import { FeedbackValues } from "./boltingOperationTable";
import { Row } from "./boltingOperationTable";
import { BoltingData } from "../../apis/types";

interface feedbackComponentInput {
	row: BoltingData;
	updateBoltingOperationDataWithFeedback: (
		row: BoltingData,
		feedback: string
	) => void;
	updateSelectedRow: (row: BoltingData) => void;
}

export function FeedbackComponent(props: feedbackComponentInput) {
	const registerFeedBack = async (feedback: string) => {
		try {
			const response = await apiHelper.patch(
				"https://ghokvtd8oj.execute-api.eu-west-1.amazonaws.com/default/update-bolting-operation",
				{
					PartitionKey: props.row["productId#processId"],
					SortKey: props.row.createdDate,
					Feedback: feedback,
				}
			);

			if (!response["Attributes"]) {
				throw Error("Setting feedback failed");
			}

			props.updateBoltingOperationDataWithFeedback(props.row, feedback);
			props.updateSelectedRow({ ...props.row, feedback: feedback });
		} catch (error) {
			console.log("Error: ", error);
			alert("Setting feedback failed");
		}
	};

	return (
		<div>
			{props.row?.feedback == "OK" || props.row?.feedback == "NOK" ? (
				<span className='body-text-content'>
					Thanks for the feedback!
				</span>
			) : (
				<span className='text-lg flex py-2p'>
					<span className='p-1 pr-1p text-nowrap'>
						Please provide feedback. Is this an anomaly?
					</span>
					<span className='body-button'>
						<DesignItButton
							onClick={() => registerFeedBack(FeedbackValues.NOK)}
						>
							Yes
						</DesignItButton>
						<DesignItButton
							onClick={() => registerFeedBack(FeedbackValues.OK)}
						>
							No
						</DesignItButton>
					</span>
				</span>
			)}
		</div>
	);
}
