import { FC, useEffect } from "react";
import SearchIcon from '@mui/icons-material/Search';
import { DesignItButton } from "@design-it/react-library";
import { useStepperContext } from "../../providers/StepperProvider";
import { BrainIcon } from "../../components/BrainIcon/BrainIcon";
import { useNavigate } from "react-router-dom";

export const StartPage: FC = () => {
  const navigate = useNavigate();
  const { setShowStepper } = useStepperContext();

  useEffect(() => {
    setShowStepper(false);
  }, []);

  return (
    <div className='pt-2'>
      <div className="grid grid-cols-2 grid-rows-[200px_400px] gap-5">
        <div className="col-span-2 text-3xl font-thin mt-7">
          How can we help you today?
        </div>

        <div className="rounded-lg bg-[#282840] flex flex-col items-center h-full justify-evenly">
          <SearchIcon style={{ fontSize: '92px' }} />

          <div className="text-3xl text-center font-thin">
            Analysis for a <br /> known error
          </div>

          <div className="text-lg">
            <DesignItButton
              class="start-button"
              variant="outlined"
              onClick={() => { console.log("navigate"); }}
            >
              <span className="mx-10 my-10">Start</span>
            </DesignItButton>
          </div>
        </div>

        <div className="rounded-lg bg-[#282840] flex flex-col items-center h-full justify-evenly">
          <BrainIcon />

          <div className="text-3xl text-center font-thin">
            Analysis for an <br /> unknown error
          </div>

          <div className="text-lg">
            <DesignItButton
              class="start-button"
              variant="filled"
              onClick={() => navigate('/analysis')}
            >
              <span className="mx-10">Start</span>
            </DesignItButton>
          </div>
        </div>
      </div>
    </div>
  );
};
