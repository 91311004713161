import { getCurrentUser } from "@aws-amplify/auth";
import {apiHelper} from "../../apis/apiHelper";

function columnsExistInDf(df, idColumns) {
	return idColumns.every(str => df.listColumns().includes(str.value));
}

function valueExistsInColumn(df, column, value) {
	return df.find(row => row.get(column) === value) !== undefined;
}

function verifyAngleList(df) {
	return (
		columnsExistInDf(df, ["MERKMALKENNUNG", "KURVENWERTE"]) &&
		valueExistsInColumn(df, "MERKMALKENNUNG", "WI")
	);
}

function verifyTorqueList(df) {
	return (
		columnsExistInDf(df, ["MERKMALKENNUNG", "KURVENWERTE"]) &&
		valueExistsInColumn(df, "MERKMALKENNUNG", "MI")
	);
}

function verifyTimeList(df) {
	return (
		columnsExistInDf(df, ["MERKMALKENNUNG", "KURVENWERTE"]) &&
		valueExistsInColumn(df, "MERKMALKENNUNG", "TI")
	);
}

export function verifyBeginningOfFile(df) {
	var failingChecks = [];

	// check unique ID
	if (!columnsExistInDf(df, ["SIDENTNR", "SAFO_NR", "TTS"])) {
		failingChecks.push("Unique ID");
	}
	// check bolting timestamp
	if (!columnsExistInDf(df, ["TTS"])) {
		failingChecks.push("Bolting timestamp");
	}
	// check bolting tool status
	if (!columnsExistInDf(df, ["LSTATUS"])) {
		failingChecks.push("Bolting tool status");
	}
	// check angle values
	if (!verifyAngleList(df)) {
		failingChecks.push("Angle values");
	}
	// check torque values
	if (!verifyTorqueList(df)) {
		failingChecks.push("Torque values");
	}
	// check time values
	if (!verifyTimeList(df)) {
		failingChecks.push("Time values");
	}

	// check for each element to be true with reduce funcitonality
	return {
		result: failingChecks.length === 0,
		failingChecks: failingChecks,
	};
}

export async function getUploadedFileConfigColumnNames() {
	try {
		const user_id = (await getCurrentUser())["userId"];

		const url =
			process.env.REACT_APP_REST_API_ROOT_URL +
			"/configurations/get-column-names";

		const resp = await apiHelper.post(url, {
			user_id,
		});
		return resp["column_names"];
	}
	catch (error) {
		console.log("Error: ", error);
	}
}

export async function verifyCorrectStructure(df) {
	let failingChecks = [];
	const body = await getUploadedFileConfigColumnNames();
	Object.entries(body).forEach(([key, value]) => {
		if (!columnsExistInDf(df, [{value}])) {
			failingChecks.push(`${value}`);
		}
	});
	// check for each element to be true with reduce funcitonality
	return {
		result: failingChecks.length === 0,
		failingChecks: failingChecks,
	};
}
