import { useState, useEffect } from 'react';

interface Option {
    process_id: string;
    name: string;
    target_torque: string;
    target_angle: string;
}

interface ModelConfigs {
    [key: string]: {
        process_id: string;
        name: string;
        target_torque: string;
        target_angle: string;
    };
}

export const useModelConfigs = (modelConfigs: ModelConfigs | null) => {
    const [loading, setLoading] = useState<boolean>(true);
    const [options, setOptions] = useState<Option[]>([]);
    const [pidOptions, setPidOptions] = useState<Array<string>>([]);

    useEffect(() => {
        const loadModelConfigs = async () => {
            try {
                if (modelConfigs) {
                    const options = Object.keys(modelConfigs).map(key => ({
                        process_id: modelConfigs[key].process_id,
                        name: modelConfigs[key].name,
                        target_torque: modelConfigs[key].target_torque,
                        target_angle: modelConfigs[key].target_angle,
                    }));
                    setOptions(options); // Update state with options

                    const allProcessIds = Array.from(
                        new Set(options.map(option => option.process_id))
                    );
                    setPidOptions(allProcessIds); // Update state with process IDs
                }
            } catch (error) {
                console.error("Error fetching model configs:", error);
            } finally {
                setLoading(false); // Set loading to false after fetching data
            }
        };

        loadModelConfigs();
    }, [modelConfigs]); // Dependency array includes modelConfigs

    return { loading, options, pidOptions };
};
