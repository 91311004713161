import { useRef, useState, DragEvent, useCallback, useEffect } from "react";
import { columnMapper } from "../dashboard/uploadHelper";
import {
  userCanUpload,
  updateUploadCount,
} from "../../utils/uploadLimitHelpers";
import AWS from "aws-sdk";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { DesignItButton } from "@design-it/react-library";
import { verifyFile } from "./uploadHelpers";
import { Navigate } from "react-router-dom";
import { useStepperContext } from "../../providers/StepperProvider";
import { useCSVContext } from "../../providers/CSVProvider";
import UploadDialog from "../../components/UploadDialog/UploadDialog";

import { getAWSConfig } from "../../utils/credentialsHelper";

export interface BoltingAttributes {
  id: string;
  processId: string;
  productId: string;
  angleValues: number[];
  torqueValues: number[];
  timeValues: number[];
  boltingToolTimestamp: string;
  boltingToolResult: string;
}

export const UploadPage = () => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [file, setFile] = useState<File | null>(null);
  const [uploadHandler, setUploadHandler] = useState<any>(null);
  const [showUploadModel, setShowUploadModel] = useState<boolean>(false);
  const { setStep, setShowStepper } = useStepperContext();

  useEffect(() => {
    setStep(1);
    setShowStepper(true);
    // cleanup --> move to proper API call for step2
    localStorage.removeItem("fileLines");
    localStorage.removeItem("lines");
  }, []);

  const { setLines, lines, progress, setProgress, fileName, setFileName } =
    useCSVContext();

  const onBrowseFileClick = () => {
    fileInputRef.current?.click();
  };

  const handleUpload = async () => {
    if (!userCanUpload()) {
      alert("User has already reached upload limit!");
      return;
    }

    if (!file) {
      console.error("No file to upload.");
      alert("No file selected for upload.");
      return;
    }

    const s3 = new AWS.S3({
      credentials: (await getAWSConfig()).credentials,
      apiVersion: "2006-03-01",
    });

    try {
      const uploadBucketName =
        process.env.REACT_APP_UPLOAD_BUCKET_NAME ?? "raw-upload-bucket";
      const params = {
        Bucket: uploadBucketName,
        Key: `raw/${file.name}`,
        Body: file,
      };
      setFileName(file.name);
      const managedUpload = s3.upload(params);

      setUploadHandler(managedUpload);
      setShowUploadModel(true);

      managedUpload.on("httpUploadProgress", function (evt) {
        const percentCompleted = Math.round((evt.loaded / evt.total) * 100);

        setProgress(percentCompleted);
      });

      await managedUpload.promise();

      updateUploadCount(1);

      // const jsonData = await triggerLambdaAndDownloadJSON(file.name);

      const fileLines = [{}];
      setLines(fileLines);
    } catch (err) {
      if ((err as Error).name === "RequestAbortedError") {
        alert("Upload cancelled");
      } else {
        alert("Failed to upload file.");
      }
    }
  };

  const onFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files?.length) {
      const newFile = event.target.files[0];
      try {
        await verifyFile(newFile);
        setFile(newFile);
      } catch (err: any) {
        setFile(null);
        alert(err);
      }
    }
  };

  const handleDragEnter = useCallback((event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
  }, []);

  const handleDragOver = useCallback((event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
  }, []);

  const handleDragLeave = useCallback((event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
  }, []);

  const handleDrop = async (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();

    const droppedFiles: Array<File> = Array.from(event.dataTransfer.files);
    const errorMessages: string[] = [];

    if (droppedFiles.length > 0) {
      try {
        await verifyFile(droppedFiles[0]);
        setFile(droppedFiles[0]);
      } catch (err: any) {
        setFile(null);
        errorMessages.push(err);
      }
    }

    if (errorMessages.length > 0) {
      const errMsg = errorMessages.join("\n\n");

      alert(errMsg);
    }
  };

  const onInputClick = (
    event: React.MouseEvent<HTMLInputElement, MouseEvent>
  ) => {
    const element = event.target as HTMLInputElement;
    element.value = "";
  };

  const handleDelete = () => {
    setFile(null);
  };

  const handleCancelUpload = () => {
    uploadHandler.abort();
    setLines([]);
    setFile(null);
    setProgress(0);
    setShowUploadModel(false);
  };

  if (progress === 100 && lines.length > 0) {
    setProgress(0);

    return <Navigate to={`/data-check?fileName=${fileName}`} />;
  }

  return (
    <div>
      <div>
        <h1 className="mt-6 text-center padding-large-top">
          Give your historic production data a fast anomaly check!
        </h1>

        <h5 className="upload-bottom-text margin-zero padding-large-left">
          <div>
            Please upload a csv-File with the following named columns and
            seperated by commas:
          </div>
        </h5>

        <div className="file-column-grid flex bg-[#8eda81] my-4 h-12 w-full">
          <div className="file-column-name ">Customer Process ID</div>
          <div className="file-column-name">Customer Product ID</div>
          <div className="file-column-name">Angle-Values</div>
          <div className="file-column-name">Torque-Values</div>
          <div className="file-column-name">Time-Values</div>
          <div className="file-column-name">Bolting-Tool Result</div>
          <div className="file-column-name">Bolting-Tool Timestamp</div>
          <div className="file-column-name">ID</div>
        </div>

        <div className="flex items-center justify-center">
          <input
            accept="*/*"
            style={{ display: "none" }}
            id="raised-button-file"
            type="file"
            onChange={onFileChange}
          />

          <div
            className="upload-container py-10"
            onDragEnter={handleDragEnter}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
          >
            {!file && <UploadFileIcon className="upload-icon" />}

            {file && (
              <div>
                <span className="file-name-text">File Name: {file.name}</span>

                <IconButton
                  onClick={() => handleDelete()}
                  color="error"
                  className="delete-file-button"
                  aria-label="delete"
                >
                  <DeleteIcon />
                </IconButton>
              </div>
            )}

            <div className="upload-container-text-button">
              <p style={{ color: "#DEDEDE" }}>Drag and drop the file</p>

              <p style={{ color: "#DEDEDE", margin: 0 }}>or</p>

              <DesignItButton
                className="browse-button"
                iconName="drive_folder_upload"
                variant="outlined"
                onClick={onBrowseFileClick}
              >
                <span>Browse file</span>
              </DesignItButton>
            </div>

            <input
              type="file"
              ref={fileInputRef}
              style={{ display: "none" }}
              onChange={onFileChange}
              onClick={onInputClick}
            />
          </div>
        </div>

        <DesignItButton
          class="full-width-button"
          variant="filled"
          onClick={handleUpload}
        >
          Let's Go
        </DesignItButton>
      </div>

      {showUploadModel && (
        <UploadDialog
          uploadProgress={progress}
          handleCancelUpload={handleCancelUpload}
        />
      )}
    </div>
  );
};
