import { useSearchParams, Navigate } from "react-router-dom";
import { useGetModelAlreadyExists } from "../../apis/useGetModelAlreadyExists";
import { useGetPollProgress } from "../../apis/useGetPollProgress";
import LoadingScreenProgressBar from "../../components/loadingScreen/progressBar";
import { useStepperContext } from "../../providers/StepperProvider";
import { useEffect } from "react";

export const AiComputingPage = () => {
  const [searchParams] = useSearchParams();
  const jobId = searchParams.get("jobId");
  const fileName = searchParams.get("fileName");
  const configName = searchParams.get("configName");

  const { data: modelExists, isLoading } = useGetModelAlreadyExists(
    configName ?? "config1"
  );
  const { data: pollProgress } = useGetPollProgress(jobId);

  const { setShowStepper } = useStepperContext();

  useEffect(() => {
    setShowStepper(false);
  }, []);

  // @TODO Add proper load
  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (pollProgress === 1) {
    if (modelExists) {
      return (
        <Navigate
          to={`/results?fileName=${fileName}&configName=${configName}`}
        />
      );
    }

    return (
      <Navigate
        to={`/labelling?fileName=${fileName}&configName=${configName}`}
      />
    );
  }

  return (
    <div className="flex h-96 flex-col items-center justify-center">
      <div>
        <LoadingScreenProgressBar value={pollProgress ?? 0} />
      </div>

      {Boolean(modelExists) && (
        <div>
          Using your personalized AI model to analyze the data.
          <br />
          Results will be available shortly. Please wait.
        </div>
      )}

      {!modelExists && (
        <p>
          Training a new AI model to analyze your data.
          <br />
          Results will be available shortly. Please wait.
        </p>
      )}
    </div>
  );
};
