import { createContext, useContext, PropsWithChildren, useState, useEffect } from 'react';

export type InitStateType = {
  lines: any[];
  setLines: (lines: any[]) => void;
  progress: number;
  setProgress: (progress: number) => void;
  fileName: string;
  setFileName: (fileName: string) => void;
};

const initState: InitStateType = {
  lines: [],
  setLines: () => {},
  progress: 0,
  setProgress: () => {},
  fileName: '',
  setFileName: () => {},
};

const CSVContext = createContext(initState);

export const useCSVContext = () => {
  const context = useContext(CSVContext);

  return context;
};

export const CSVProvider = ({
  children
}: PropsWithChildren) => {
  const [lines, setLines] = useState<any[]>(initState.lines);
  const [progress, setProgress] = useState<number>(initState.progress);
  const [fileName, setFileName] = useState<string>(initState.fileName);

  return (
    <CSVContext.Provider value={{
      lines,
      setLines,
      progress,
      setProgress,
      fileName,
      setFileName
    }}>
      {children}
    </CSVContext.Provider>
  );
};
