import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import { ApiError } from './httpResponse';
import { getCurrentUser } from "@aws-amplify/auth";
import { mapDbBoltingDataToBoltingData } from "./apiMappers";
import { apiHelper } from "./apiHelper";
import { BoltingData, DbBoltingData } from './types';

const URL = `${process.env.REACT_APP_REST_API_ROOT_URL}/get-bolting-operations`;

const getAllBoltingData = async (
	userId: string,
	extraFilters?: Record<string, string>
): Promise<BoltingData[]> => {
	let requestBody: any = {
		user_id: userId,
		page_size: 1000,
		page_number: 1,
		use_new_implementation: true,
	};

	if (extraFilters) {
		requestBody.additional_filters = { ...extraFilters };
	}

	const data = await apiHelper.post(URL, requestBody);

	return (data.body as unknown as DbBoltingData[])
		?.map(mapDbBoltingDataToBoltingData)
		?? [];
};

const getBoltingDataByUserId = async () => {
	const { userId } = await getCurrentUser();
	const data = await getAllBoltingData(userId);

	return data;
};

export const useGetBoltingData = (
	options?: Omit<UseQueryOptions<
		unknown,
		ApiError,
		any
	>,
		'queryKey' | 'queryFn'>
) => useQuery({
	queryKey: ['get-bolting-data'],
	queryFn: () => getBoltingDataByUserId(),
	...options,
});

const getBoltingDataByUserIdWithAnomalies = async () => {
	const { userId } = await getCurrentUser();
	const data = await getAllBoltingData(userId, { anomaly_detected: "yes" } );

	return data;
};

export const useGetBoltingDataWithAnomalies = () =>
	useQuery({
		queryKey: ['get-bolting-data-with-anomalies'],
		queryFn: getBoltingDataByUserIdWithAnomalies,
	});

const getBoltingDataById = async (id: string | undefined) => {
	if (!id) {
		return null;
	}

	const { userId } = await getCurrentUser();
	const data = await getAllBoltingData(userId, { id });

	return data?.[0];
};

export const useGetBoltingDataById = (id: string | undefined) =>
	useQuery({
		queryKey: ['get-bolting-data-by-id', id],
		queryFn: () => getBoltingDataById(id),
	});
