import httpRequest from '../providers/httpRequestProvider';
import { endpoints } from './endpoints';
import { UseMutationOptions, useMutation } from '@tanstack/react-query';
import { ApiError } from './httpResponse';

export type UpdateBoltingDataFeedbackRequest = {
    updates: UpdateBoltingDataFeedback[];
};

export type UpdateBoltingDataFeedback = {
    PartitionKey: string;
    SortKey: string;
    Feedback: string;
};

const updateBoltingDataFeedback = async (
    data: UpdateBoltingDataFeedback[]
): Promise<unknown> => {
    const body: UpdateBoltingDataFeedbackRequest = { updates: data };

    const response = await httpRequest.post<unknown>(
        endpoints.UPDATE_BOLTING_DATA_FEEDBACK,
        { ...body }
    );

    return response;
};

export const useUpdateBoltingDataFeedback = (
    options?: UseMutationOptions<
        unknown,
        ApiError,
        UpdateBoltingDataFeedback[]
    >
) => useMutation({
    mutationFn: (data: UpdateBoltingDataFeedback[]) => updateBoltingDataFeedback(data),
    ...options
});
