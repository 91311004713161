import React from "react";
import {
  DesignItCheckbox,
  DesignItButton,
  DesignItIconButton,
  DesignItSnackbar,
} from "@design-it/react-library";
import * as newAmplify from "@aws-amplify/auth";
import { useNavigate } from "react-router-dom";
import { fetchAuthSession } from "@aws-amplify/auth";
import "@design-it/web-core/dist/assets/themes/themes.scss";
import "./login.scss";
import { setCookie, removeCookie } from "../../utils/cookieHelper";
import BoltItInput from "../../components/Input/index";
import { configureCredentials } from "../../utils/credentialsHelper";
import { Amplify } from "aws-amplify";
import { useStepperContext } from "../../providers/StepperProvider";

Amplify.configure({
  Auth: {
    Cognito: {
      userPoolId: process.env.REACT_APP_USER_POOL_ID || "",
      userPoolClientId: process.env.REACT_APP_USER_POOL_CLIENT_ID || "",
      loginWith: {
        oauth: {
          domain: process.env.REACT_APP_COGNITO_DOMAIN || "",
          scopes: [
            "aws.cognito.signin.user.admin",
            "email",
            "openid",
            "phone",
            "profile",
          ],
          redirectSignIn: process.env.REACT_APP_COGNITO_REDIRECT_URL
            ? [process.env.REACT_APP_COGNITO_REDIRECT_URL]
            : ["https://dev.bolt-it.net/"],
          redirectSignOut: process.env.REACT_APP_COGNITO_REDIRECT_URL
            ? [process.env.REACT_APP_COGNITO_REDIRECT_URL]
            : ["https://dev.bolt-it.net/"],
          responseType: "code",
        },
      },
    },
  },
});

const Login = () => {
  const history = useNavigate();
  const [staySignedChecked, setStaySignedInCheck] = React.useState(false);
  const [newUser, setNewUser] = React.useState(false);
  const [formValues, setFormValues] = React.useState({
    userName: "",
    password: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [showPassword, setShowPassword] = React.useState(false);
  const [showNewPassword, setShowNewPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);

  const { setShowStepper } = useStepperContext();

  const handleStaySignedIn = (value: boolean) => {
    setStaySignedInCheck(value);
    if (value) {
      setCookie("stay_login", `${value}`, 10);
    } else {
      removeCookie("stay_login");
    }
  };

  React.useEffect(() => {
    setShowStepper(false);
    checkAuthSession();
  }, []);

  const checkAuthSession = async () => {
    try {
      const session = await fetchAuthSession();

      if (session.tokens) {
        history("/start");
      }
    } catch (error) {
      console.error("Error fetching auth session", error);
    }
  };

  const handleSubmit = async () => {
    try {
      try {
        await newAmplify.getCurrentUser();
        history("/start");
      } catch (error) {
        if (newUser) {
          // New user sign-up flow
          try {
            if (formValues.newPassword !== formValues.confirmPassword) {
              console.error("Passwords do not match");
              openPasswordMismatchSnackbar();
              return;
            } else {
              const newUser = await newAmplify.confirmSignIn({
                challengeResponse: formValues.newPassword,
              });

              await newAmplify.signOut();
              openConfirmationSnackbar();
              setTimeout(() => {
                history("/start");
              }, 5000);
            }
          } catch (error: any) {
            if (error.name === "NotAuthorizedException") {
              openNotAuthorizedSnackbar();
            } else if (error.name === "InvalidPasswordException") {
              openInvalidPasswordSnackbar();
            } else {
              console.error("Error signing up:", error);
              openPasswordMismatchSnackbar();
            }
          }
        } else {
          // Existing user sign-in flow
          try {
            const user = await newAmplify.signIn({
              username: formValues.userName,
              password: formValues.password,
              options: {
                authFlowType: "USER_PASSWORD_AUTH",
                device: staySignedChecked ? "remembered" : "not_remembered",
              },
            });
            if (
              user.nextStep.signInStep ===
              "CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED"
            ) {
              setNewUser(true);
            } else {
              configureCredentials();
              history("/start");
            }
          } catch (signInError) {
            console.error("Error signing in:", signInError);
            openSnackbar();
          }
        }
      }
    } catch (error) {
      console.error("Unexpected error:", error);
      openSnackbar();
    }
  };

  const openSnackbar = () => (document.getElementById("sb5") as any)?.show();
  const openPasswordMismatchSnackbar = () =>
    (document.getElementById("sb6") as any)?.show();
  const openInvalidPasswordSnackbar = () =>
    (document.getElementById("sb7") as any)?.show();
  const openNotAuthorizedSnackbar = () =>
    (document.getElementById("sb8") as any)?.show();
  const openConfirmationSnackbar = () =>
    (document.getElementById("sb9") as any)?.show();

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      handleSubmit();
    }
  };

  return (
    <div className="login-container" data-testid="login-container">
      <div className="login-img-container">
        <div>
          <span className="bolt-text">bolt</span>
          <span className="it-text">_it</span>
        </div>
        <div>
          <img
            data-testid="background-image"
            src={require("../../assets/images/background.png")}
            className="img"
          />
        </div>
      </div>
      <div className="login-form-container">
        <div className="form-box">
          <div className="form-input-box">
            {!newUser && (
              <>
                <div className="login-heading-container">
                  <span className="welcome-text">Welcome Back!</span>
                  <span className="account-text">Log into your account</span>
                </div>
                <BoltItInput
                  className="input-text"
                  class="full-width-input"
                  role="input"
                  id="test"
                  label="Email address or Username"
                  variant="outlined"
                  value={formValues.userName}
                  onInput={(event: any) =>
                    setFormValues(preValue => ({
                      ...preValue,
                      userName: event.target.value,
                    }))
                  }
                  onKeyDown={handleKeyDown}
                  data-testid="email-input"
                />
                <BoltItInput
                  className="input-text"
                  role="input"
                  class="full-width-input"
                  label="Password"
                  type={showPassword ? "text" : "password"}
                  variant="outlined"
                  value={formValues.newPassword}
                  onInput={(event: any) =>
                    setFormValues(preValue => ({
                      ...preValue,
                      password: event.target.value,
                    }))
                  }
                  onKeyDown={handleKeyDown}
                  data-testid="password-input"
                >
                  <DesignItIconButton
                    toggle
                    slot="trailing-icon"
                    variant="icon"
                    icon={showPassword ? "visibility" : "visibility_off"}
                    selected-icon={"visibility"}
                    value={formValues.password}
                    onMouseDown={event => {
                      event.preventDefault();
                      setShowPassword(!showPassword);
                    }}
                  ></DesignItIconButton>
                </BoltItInput>
                <div className="input-text form-Bottom-section">
                  <DesignItCheckbox
                    value={staySignedChecked.toString()}
                    onInput={(event: any) =>
                      handleStaySignedIn(event.target.checked)
                    }
                  >
                    Stay signed in
                  </DesignItCheckbox>
                  {/* <DesignItButton variant="text">Forgot password?</DesignItButton> */}
                </div>
                <div>
                  <div style={{ width: "100%" }} data-testid="submit-button">
                    <DesignItButton
                      class="full-width-button"
                      variant="filled"
                      onClick={handleSubmit}
                    >
                      Sign in
                    </DesignItButton>
                  </div>
                  <DesignItSnackbar
                    id="sb5"
                    position="top-right"
                    duration={2000}
                    message="User is not present or incorrect email or password"
                  />
                </div>
              </>
            )}
            {newUser && (
              <>
                <div className="login-heading-container">
                  <span className="account-text">
                    Create Your Account Password
                  </span>
                </div>
                <BoltItInput
                  className="input-text"
                  role="input"
                  class="full-width-input"
                  label="Password"
                  type={showNewPassword ? "text" : "password"}
                  variant="outlined"
                  value={formValues.newPassword}
                  onInput={(event: any) =>
                    setFormValues(preValue => ({
                      ...preValue,
                      newPassword: event.target.value,
                    }))
                  }
                  onKeyDown={handleKeyDown}
                  data-testid="password-input"
                >
                  <DesignItIconButton
                    toggle
                    slot="trailing-icon"
                    variant="icon"
                    icon={showNewPassword ? "visibility" : "visibility_off"}
                    selected-icon={"visibility"}
                    value={formValues.newPassword}
                    onMouseDown={event => {
                      event.preventDefault();
                      setShowNewPassword(!showNewPassword);
                    }}
                  ></DesignItIconButton>
                </BoltItInput>
                <BoltItInput
                  className="input-text"
                  role="input"
                  class="full-width-input"
                  label="Confirm Password"
                  type={showConfirmPassword ? "text" : "password"}
                  variant="outlined"
                  value={formValues.confirmPassword}
                  onInput={(event: any) =>
                    setFormValues(preValue => ({
                      ...preValue,
                      confirmPassword: event.target.value,
                    }))
                  }
                  onKeyDown={handleKeyDown}
                >
                  <DesignItIconButton
                    toggle
                    slot="trailing-icon"
                    variant="icon"
                    icon={showConfirmPassword ? "visibility" : "visibility_off"}
                    selected-icon={"visibility"}
                    value={formValues.confirmPassword}
                    onMouseDown={event => {
                      event.preventDefault();
                      setShowConfirmPassword(!showConfirmPassword);
                    }}
                  ></DesignItIconButton>
                </BoltItInput>
                <div className="input-text">
                  <div style={{ width: "100%" }}>
                    <DesignItButton
                      class="full-width-button"
                      variant="filled"
                      onClick={handleSubmit}
                    >
                      Change Password
                    </DesignItButton>
                  </div>
                  <DesignItSnackbar
                    id="sb6"
                    position="top-right"
                    duration={2000}
                    message="Passwords do not match"
                  />
                  <DesignItSnackbar
                    id="sb7"
                    position="top-right"
                    duration={4000}
                    message="Password does not match minimum requirement. Should have minimum 8 characters with at least one lowercase letter"
                  />
                  <DesignItSnackbar
                    id="sb8"
                    position="top-right"
                    duration={4000}
                    message="Session is expired"
                  />
                  <DesignItSnackbar
                    id="sb9"
                    position="top-right"
                    duration={4000}
                    message="Password successfully changed. Redirecting to SignIn"
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
