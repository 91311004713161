import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { DesignItButton } from "@design-it/react-library";
import { DragEvent, RefObject, FC } from "react";
import UploadDialog from "../UploadDialog/UploadDialog";
import { useCSVContext } from "../../providers/CSVProvider";
import "./uploadDropContainer.scss";
import { S3 } from "aws-sdk";

export interface UploadDropContainerProps {
  file: File | null;
  fileInputRef: RefObject<HTMLInputElement>;
  hasError?: boolean;
  setFile: (file: File | null) => void;
  verifyFile: (file: File) => Promise<void>;
  uploadHandler: S3.ManagedUpload | null;
  setShowUploadModal: (showUploadModal: boolean) => void;
  showUploadModal: boolean;
}

export const UploadDropContainer: FC<UploadDropContainerProps> = props => {
  const { setLines, progress, setProgress } = useCSVContext();

  const onBrowseFileClick = () => {
    props.fileInputRef.current?.click();
  };

  const handleDelete = () => {
    props.setFile(null);
  };

  const handleDragEnter = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleDragOver = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleDragLeave = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleDrop = async (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();

    const droppedFiles: Array<File> = Array.from(event.dataTransfer.files);
    const errorMessages: string[] = [];

    if (droppedFiles.length > 0) {
      try {
        await props.verifyFile(droppedFiles[0]);
        props.setFile(droppedFiles[0]);
      } catch (err: unknown) {
        props.setFile(null);
        errorMessages.push(err as string);
      }
    }

    if (errorMessages.length > 0) {
      const errMsg = errorMessages.join("\n\n");

      alert(errMsg);
    }
  };

  const onFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files?.length) {
      const newFile = event.target.files[0];

      try {
        await props.verifyFile(newFile);
        props.setFile(newFile);
      } catch (err: unknown) {
        props.setFile(null);
        alert(err);
      }
    }
  };

  const onInputClick = (
    event: React.MouseEvent<HTMLInputElement, MouseEvent>
  ) => {
    const element = event.target as HTMLInputElement;
    element.value = "";
  };

  const handleCancelUpload = () => {
    props.uploadHandler?.abort();
    setLines([]);
    props.setFile(null);
    setProgress(0);
    props.setShowUploadModal(false);
  };

  return (
    <>
      <div className="w-[450px] h-[320px] flex justify-center items-center flex-col ">
        <div
          className={
            "p-[50px] font-thin rounded-xl border-2 border-dashed w-full" +
            (props.hasError ? " border-error" : " border-outline")
          }
        >
          <input
            accept=".csv, text/csv"
            id="raised-button-file"
            type="file"
            onChange={onFileChange}
            className="hidden"
          />
          <div
            onDragEnter={handleDragEnter}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
            className="flex items-center justify-center flex-col gap-4"
          >
            {!props.file && (
              <UploadFileIcon htmlColor="#8EDA81" id="upload-file-icon" />
            )}

            {props.file && (
              <div>
                <span className="file-name-text">
                  File Name: {props.file.name}
                </span>

                <IconButton
                  onClick={() => handleDelete()}
                  color="error"
                  className="delete-file-button"
                  aria-label="delete"
                >
                  <DeleteIcon />
                </IconButton>
              </div>
            )}

            <p className="text-[20px]">Drag & Drop your file</p>
            <p className="text-[16px]">OR</p>

            <DesignItButton
              className="browse-button"
              iconName="drive_folder_upload"
              variant="outlined"
              onClick={onBrowseFileClick}
            >
              <span>Browse file</span>
            </DesignItButton>
            <input
              type="file"
              ref={props.fileInputRef}
              onChange={onFileChange}
              onClick={onInputClick}
              className="hidden"
            />
          </div>
        </div>
        <p
          className={"w-fit text-error " + (props.hasError ? "" : " invisible")}
        >
          Please upload a file
        </p>
      </div>
      {props.showUploadModal && (
        <UploadDialog
          uploadProgress={progress}
          handleCancelUpload={handleCancelUpload}
        />
      )}
    </>
  );
};
